.search-box {
  transform: translate(0%, 0%);
  height: 50px;
  border-radius: 30px;
  padding: 10px;
}

.search-box:hover > .search-text,
.isActiveSearch > .search-text {
  width: 240px;
  padding: 0 6px;
}

.search-box:hover > .search-btn,
.isActiveSearch > .search-btn {
  background: rgb(31, 30, 30);
  color: rgb(238, 232, 232);
}

.search-btn {
  background-color: transparent;
  float: right;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  cursor: pointer;
  text-decoration: none;
}

.search-btn > i {
  font-size: 30px;
}

.search-text {
  border: none;
  background: none;
  outline: none;
  float: left;
  padding: 0;
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-weight: normal;
  transition: 0.4s;
  line-height: 40px;
  width: 0px;

  /*   width: 250px; */
}
